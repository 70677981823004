@keyframes loading-spinner {
  to {transform: rotate(360deg);}
}

.loading-spinner {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 4px solid;
  border-color: #000 #000 #000 rgba(0,0,0,0);
  animation: loading-spinner 1s linear infinite;
}
