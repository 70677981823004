@import "/src/styles/settings";

.form-action {
  display: flex;
  justify-content: right;
}

.form-label.required::after {
  content: " *";
  color: red;
}

.form-error {
  color: $formError !important;
}
