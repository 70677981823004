@import "/src/styles/settings";

.result {
  @extend .pageSection;
  max-width: $pageW_medium;
  text-align: center;
}

.result_status {
  margin-bottom: $spacing3;
}

.result_campaignInfo {
  margin-bottom: $spacing4;
}

.result_reAction {
  @extend .pageSection;
  margin-bottom: $spacing3;
}

//form section
.result_delivery {
  margin-top: $spacing4*2;
}

.result_form {
  @extend .pageSection;
  max-width: 700px;
  margin: 0 auto;
  text-align: left;
}

.result-claimBtn {
  font-size: 1.1rem;
  width: 100px;
}

.result_claim {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.result_campaignImage {
  margin: 0 auto;
}

.result_receipt {
  max-width: 600px;
  margin: 3rem auto 3rem;
  padding: 40px 30px 20px;
  background-color: white;
  border-radius: 5px;
}

.result_form-email {
  text-align: left;
}
.result_form-submit {
  margin-top: -15px;
  text-align: right;
}

.receipt-form {
  margin-top: 30px;
  margin-bottom: 0 !important;
}

.shareBtn {
  border: none;
  padding: 7px 20px;
  border-radius: 0.25rem;
  display: inline-flex;
  margin: $spacing1/2;
  font-size: 1.15rem;
  width: 210px;
  font-family: sans-serif;
  align-items: center;
  justify-content: center;
  & > svg {
    margin-right: 0.5rem;
    font-size: $fontSize2;
  }
  & > span {
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    vertical-align: middle;
    height: 1.2rem;
  }
}

.facebookShare {
  @extend .shareBtn;
  background: #1164ce;
  color: white;
  &:hover {
    background: #0e57b6;
  }
}

.mailShare {
  @extend .shareBtn;
  background: #6195c0;
  color: white;
  text-decoration: none;
  &:hover {
    background: #467ba6;
    color: white;
  }
}

.copyUrl {
  @extend .shareBtn;
  color: #212121;
  background: #dfdfe3;
  &:hover {
    color: #000;
    background: #c8c8cb;
  }
}

@media only screen {
  @media (min-width: $breakpoint_medium) {
    .shareBtn {
      margin: 0 $spacing1/2;
    }
  }
}
