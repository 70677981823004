@import "/src/styles/settings";

.headingEffect {
  margin: 0 auto;
  width: fit-content;
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    bottom: -1rem;
    left: 0;
    width: 100%;
    margin-left: 0;
    height: 4px;
    position: absolute;
    background: linear-gradient(to right, rgba($color1, 0) 75%, rgba($color1, 0.6));
  }

  &::after {
    content: "";
    display: inline-block;
    bottom: -1rem;
    width: 100%;
    height: 4px;
    position: absolute;
    right: 0;
    margin-right: 0;
    margin-left: auto;
    background: linear-gradient(to left, rgba($color1, 0) 75%, rgba($color1, 0.6));
  }
}

//-------[Typography]-------//
h1, .h1, h2, h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: inherit;
  font-family: 'Noto Serif', serif;
  font-weight: inherit;
}

h1, .h1 {
  @extend .headingEffect;
  margin-bottom: 3rem;
  font-size: $fontSize2*2;
}

.page-heading {
  font-size: $fontSize3;
  color: $page-heading-color;
  font-weight: 400;
  margin: auto 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}

.fs1 {
  font-size: $fontSize1 * 0.75;
}
.fs2 {
  font-size: $fontSize2 * 0.75;
}
.fs3 {
  font-size: $fontSize3 * 0.75;
}
.fs4 {
  font-size: $fontSize4 * 0.75;
}
.fs5 {
  font-size: $fontSize2*2;
}

div.nw {
  text-wrap: none;
  white-space: nowrap;
}
span.nw {
  text-wrap: none;
  white-space: pre-wrap;
}

span.br, strong.br {
  display: block;
}



@media only screen {
  @media (min-width: $breakpoint_medium) {
    h1, .h1 {
      font-size: $fontSize3 * 2;
    }
    .fs1 {
      font-size: $fontSize1;
    }
    .fs2 {
      font-size: $fontSize2;
    }
    .fs3 {
      font-size: $fontSize3;
    }
    .fs4 {
      font-size: $fontSize4;
    }
    .fs5 {
      font-size: $fontSize3*2;
    }
  }
}
