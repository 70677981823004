@import "../settings";

//-------[Typography]-------//
.h1, h2, .h3, .h4, .h5, .h6 {
  color: inherit;
  font-family: 'Noto Serif', serif;
  font-weight: inherit;
}

a {
  color: inherit;

  &:hover {
    color: $color1;
  }
  &:focus {
    color: black !important;
    text-decoration: underline;
  }
}

//-------[Buttons]-------//
.btn {
  position: relative;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5) !important;
  }

  &-primary {
    background-color: $btn-primary;
    border: 1px solid #67bed7;

    &:focus {
      background-color: $btn-primary;
      border: 1px solid #67bed7
    }

    &:hover {
      background-color: #67bed7;
      border: 1px solid $btn-primary
    }
  }

  &-secondary {
    background-color: $btn-secondary;
    border: 1px solid #d7642c;

    &:focus {
      background-color: $btn-secondary;
      border: 1px solid #d7642c
    }

    &:hover {
      background-color: #d7642c;
      border: 1px solid $btn-secondary
    }
  }

  &-black {
    &:hover {
      color: $color1;
    }
  }

  &-danger {
    background-color: $btn-danger;

    &:focus {
      background-color: $btn-danger;
    }
  }

  &-warning {

  }

  &-success {
    background-color: $btn-success;
    border: 1px solid #4cb966;

    &:focus {
      background-color: $btn-success;
      border: 1px solid #4cb966;
    }

    &:hover {
      background-color: #4cb966;
      border: 1px solid $btn-success;
    }
  }

  &-greyed {
    color: white;
    background-color: $btn-greyed;
    border: 1px solid #b2b2b2;

    &:focus {
      background-color: $btn-greyed;
      border: 1px solid #b2b2b2
    }

    &:hover {
      color: white;
      background-color: #b2b2b2;
      border: 1px solid $btn-greyed
    }
  }

  & .btn-loading {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    & > svg {
      vertical-align: unset;
    }
  }

  &:hover > a, a:hover {
    color: inherit;
  }
}

//-------[Forms]-------//

.form-control {
  &:focus {
    border: none;
    box-shadow: 0 0 0 0.25rem rgba($color1, 0.25);
  }

  &.error {
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba($formError, 0.25);
    border: 1px solid $formError;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.25rem rgba($formError, 0.25);
      border: 1px solid $formError;
    }
  }
}

input[type="checkbox"] {
  cursor: pointer;
}

.form-check-input {
  display: inline-block;
  padding: 12px;
  &:checked {
    background-color: $color1;
    border-color: $color1;
  }
  &:focus {
    border: 1px solid $formError;
    box-shadow: 0 0 0 0.25rem rgba($formError, 0.25);
  }
}
