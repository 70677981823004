.scratch {
  @extend .pageSection;
  text-align: center;
  overscroll-behavior-x: none;
}

.scratchDisplay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0 15px;


  & .carousel-root {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }

  .ScratchCard__Canvas {
    z-index: 3;
  }

  .carousel-status {
    top: $spacing1 !important;
    pointer-events: none;
  }
}

.scratchControl {
  display: flex;
  flex-direction: column;

  > * {
    margin: $spacing1 / 2;
  }
}

@media only screen {
  @media (max-width: 500px) {
    .scratch {
      touch-action: none;
    }
  }
}
