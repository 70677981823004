@import "src/styles/settings";

.landing {
  @extend .pageSection;
  min-height: calc(100vh - #{$pageOffset_top});
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .landing_content {
    width: 90vw;
    max-width: 1600px;
    padding-top: $spacing3;
  }

  h1 {
    font-size: $fontSize3*2;

    strong {
      font-size: $fontSize2*2;
    }
  }

  .features {
    margin-top: $spacing4;
  }

  .feature-item {
    padding: $spacing2;
    .icon {
      margin: 0 auto;
      border-radius: 100%;
      width: 90px;
      height: 90px;
      background-color: #dedede;
      display: flex;
      align-items: center;
      justify-content: center;

      & > svg {
        font-size: 2rem;
      }
    }
  }

  .landing_actions {
    margin-top: $spacing4;

    .readMore {
      .btn {
        margin-top: $spacing1;
      }
    }
  }
}

@media screen {
  @media (min-width: $breakpoint_medium) {
    .landing_content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
