@import "/src/styles/settings";

.nav {
  @include borderBottom;
  display: flex;
  align-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  padding: ($spacing1/3) $spacing1;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: $navHeight_desktop;
}

.nav_container {
  margin: 0 auto;
  width: 100%;
  max-width: $pageW_medium;
  display: flex;
  flex-direction: row;
}
.nav-logo {
  max-height: 50px;
  img {
    height: 40px;
  }
}
.nav-right {
  margin-left: auto;
  display: none;

  .nav-links {
    .nav-link:last-child {
      padding-right: 0;
    }
  }
}
.nav-left {
  margin: 0 auto;
}
.nav-links {
  display: none;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin: auto;
  padding: 0;
}

.nav-refs {
  display: flex;
  flex-direction: row;
  margin: 0 0 5px auto;
  padding: 0 2px 0 0;
  text-align: right;
}

.nav-ref {
  padding: 0 $spacing1;
}

.nav-link {
  color: $color5;

  &:hover {
    color: $color1;
  }
}

@media only screen {
  @media (min-width: $breakpoint_medium) {
    .nav-links {
      display: flex;
    }
    .nav-right {
      display: flex;
    }
    .nav-left {
      margin: 0;
    }
  }
}
