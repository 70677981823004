.row {
  display: flex;
  flex-direction: row;
}

.row.nowrap {
  flex-wrap: nowrap;

  & > * {
    flex-shrink: unset;
  }
}

.col-fill {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-content: center;
}

.col {
  &-sm-fill {
    @extend .col-fill;
  }

  &-md-fill {
    @extend .col-fill;
  }

  &-lg-fill {
    @extend .col-fill;
  }
}
