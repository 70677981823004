@import "./src/styles/settings";
body {
  min-height: 100vh;
}

.page {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: $pageOffset_top 5px 5px;
  margin: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background-color: $backgroundColor_main;
  font-family: "Verdana", 'Open Sans', sans-serif;
  font-weight: 400;
  /*background: url("http://www.clker.com/cliparts/Z/a/8/a/B/z/wallpaper-of-yellow-orange-pink-red-mixed-combination-background-hi.png");
  background-size: cover;
  color: white;*/
}

.heroContent {
  margin: 0 (-5px);
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.contentWrapper {

  &:empty {
    border: none;
  }
}

.pageSection {
  margin: $spacing3 auto $spacing3;
  width: 90vw;
  max-width: $pageW_medium;

  &.split::before {
    display: block;
    border-top: 1px solid #cecdcd;
    content: "";
    height: 1px;
    margin: auto auto $spacing3;
  }

  &.slit {
    margin: 0 auto $spacing4;
  }

  &:last-child {
    margin-bottom: $spacing3;
  }
}


.hidden {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -9999;
}





@media only screen {
  @media (min-width: $breakpoint_medium) {
    .page {
      padding: $pageOffset_top $spacing3 $spacing3;
    }
    .heroContent {
      margin: 0 (-$spacing3);
    }
    .pageSection {
      &:last-child {
        margin-bottom: $spacing1;
      }
    }
  }
}
