@import "./settings";
*, *::before, *::after {
  box-sizing: border-box;
}

.body {
  color: $fontColor_primary;
}

.link {
  cursor: pointer;
}

.to-topBtn {
  position: fixed;
  bottom: 50px;
  right: 10px;
  font-size: $fontSize3;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton {
  width: fit-content;
  position: absolute;
  top: 10px;
  right: 0;
}

.closeButton {
  width: fit-content;
  position: absolute;
  top: 10px;
  right: 0;
}

@mixin borderTop {
  &:before {
    position: absolute;
    top: -3px;
    left: 0;
    z-index: 0;
    height: 3px;
    width: 100%;
    background: linear-gradient(to right, $color1, $color1);
    content: "";
    margin: 0;
    border-radius: inherit;
  }
}

@mixin borderBottom {
  &:after {
    position: absolute;
    bottom: -3px;
    left: 0;
    z-index: 0;
    height: 3px;
    width: 100%;
    background: linear-gradient(to right, $color1, $color1);
    content: "";
    margin: 0;
    border-radius: inherit;
  }
}
