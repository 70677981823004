@import "src/styles/settings";

.campaignQR{

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    margin: auto;
    height: calc(100vh - #{$navHeight_desktop});
  }

  &-title {
    margin-bottom: 80px;
  }

  &-manager {
    margin-top: 30px;
  }
}
