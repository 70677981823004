@import "/src/styles/settings";

//-------[INFO]-------//
.campaignHero {
  @extend .heroContent;
  position: relative;
}

.campaignHero_hero {
  min-height: 60vh;
  display: flex;
  padding: $spacing1/2;
}

.campaignHero_heading {
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.80);
  text-align: center;
  padding: $spacing3 $spacing1;
  margin: auto;
  width: fit-content;
  max-width: 1200px;

  & h1 {
    font-size: calc(0.5rem + 5vw);
    word-break: break-word;
  }
  & h2 {
    font-size: calc(0.3rem + 5vw);
  }
}

.campaignHero_body {
  margin: $spacing2 0 0;
  text-align: center;
  z-index: 2;
}

.campaignHero_description {
  font-size: calc(0.25rem + 4vw);
}

.campaignHero-separator {
  height: 4px;
  background: linear-gradient(to right, $color1, $color1);
}

//-------[BODY]-------//
.campaignBody {
  @extend .pageSection;
  margin: $spacing2 auto 0;
  text-align: center;
  max-width: 900px;
  font-size: $fontSize1;
}


//-------[RAFFLE]-------//
.campaignRaffle {
  @extend .pageSection;
  text-align: center;
}

.rafflePlay {
  display: flex;
  flex-direction: column;
}

.rafflePlay_select {
  display: flex;
  flex-direction: row;
  margin: 0 auto;

  & > * {
    margin: 0 ($spacing1 / 2);
  }

  .btn {
    display: flex;
    padding: 0 $spacing1;
    width: 110px;
    font-size: $fontSize2;
    justify-content: center;
    align-items: center;
  }

  .form-control {
    text-align: center;
    max-width: 150px;
    font-size: $fontSize1;
  }
}

.raffleInfo {
  margin-bottom: $spacing2;
}

.raffleActions {
  margin-top: $spacing1;

  .btn {
    padding: ($spacing2/2) $spacing4;
    font-size: $fontSize1;
  }
}

.raffleCost {
  padding-top: $spacing1;
  font-size: $fontSize2;
}

//-------[PRIZES]-------//
.campaignPrizes {
  @extend .pageSection;
  position: relative;
}

.prizesInfo {
  text-align: center;
  font-size: $fontSize2;
  max-width: 700px;
  margin: 0 auto $spacing2;

  h2 {
    font-size: $fontSize4;
  }

  p {
    margin: 0;
  }
}

.prizesCarousel {
  & img {
    max-height: 100%;
    max-width: 100%;
    width: unset !important;
    object-fit: contain;
  }

  & .slider-wrapper {
    border-radius: 0.25rem;
    background-color: white;
    padding: 5px;
  }

  & .slide {
    max-height: 600px;
  }

  & .thumbs-wrapper {
    overflow: visible;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  & .thumbs {
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: left;
    transform: unset !important;
    margin: 0 auto 1rem !important;
    width: fit-content;
    max-width: 100%;
    max-height: 600px;
  }

  & .thumb {
    & > img {
      height: 100px;
      width: 100% !important;
      background-color: white;
    }
  }
}

//-------[STATUS]-------//
.campaignStatus {
  @extend .pageSection;
  text-align: center;
}

.campaignStatus-reminding {
  font-size: $fontSize2;
  margin-bottom: $spacing1;
}

//-------[MEDIA QUERIES]-------//

@media only screen {
  @media (min-width: $breakpoint_medium) {
    .campaignHero_hero {
      padding: $spacing2;
    }
    .campaignHero_heading {
      padding: $spacing3 $spacing4;
      & h1 {
        font-size: 3rem;
      }
      & h2 {
        font-size: 2rem;
      }
    }
    &.campaignHero_description {
      font-size: 1.25rem;
    }
  }
}
