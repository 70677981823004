.carousel-slider {
  max-height: fit-content;
  height: 100%;

  .slider-wrapper {
    position: relative;
  }

  .control-customArrow {
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    display: none;
    position: absolute;
    top: 50% !important;
    transform: translate(0, -50%);
    bottom: unset;
    height: 50px;
    width: 50px;
    background-color: #f3f3f3;
    color: $color1;
    align-content: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
    z-index: 10;

    &:hover {
      transform: translate(0, -50%) scale(1.05);
    }

    &.next {
      right: 10px !important;
    }

    &.prev {
      left: 10px !important;
    }

    svg {
      display: flex;
      margin: auto;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    .control-customArrow {
      display: flex;
    }
  }
}

.carousel {
  .thumbs {
    padding: 0;
    justify-content: center !important;
  }

  .thumb {
    margin: 3px !important;

    img {
      object-fit: cover !important;
    }
  }
}

.carousel-status {
  display: block;
  transform: translate(50%, 0) !important;
  width: fit-content;
  right: 50% !important;
  font-size: 1rem !important;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid $color1;
}
