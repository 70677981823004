@import "/src/styles/settings";

.footer {
  @include borderTop;
  position: relative;
  width: 100%;
  padding: $spacing3 $spacing1 $spacing2;
  display: flex;
  flex-direction: column;
  & > div {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer-top {
  margin-bottom: $spacing2;
}

.footer-logo {
  margin: 0 auto;
  height: 40px;
}

.footer-links {
  margin-bottom: $spacing2;
}

.footer-agreements {
  justify-content: center;
}

@media only screen {
  @media (min-width: $breakpoint_medium) {
    .footer-links {
      display: none;
    }
  }
}
