//------------[Breakpoints]-------------//

//grid
$breakpoint_small: 32.5em;
$breakpoint_medium: 56.25em;
$breakpoint_mediumLarge: 90em;
$breakpoint_Large: 130em;
$breakpoint_xLarge: 160em;


//----------------[colors]----------------//

//colorTheme
$color1: #ef6e2d;
$color2: #e70f7f; //e70f7f
$color3: #a3a3a3;
$color4: #222222;
$color5: #3d3d3d;
$color6: #000000;
$white: #ffffff;
$black: #252525;

//background colors
$backgroundColor_main: #f6f6f6;

//global colors
$footerColor: none;
$dropShadowColor_main: rgba(0, 0, 0, 0.3);

//buttons
$btn-primary: $color2;
$btn-secondary: $color1;
$btn-danger: #b92525;
$btn-success: #3aa854;
$btn-warning: #fab505;
$btn-greyed: #8c8c8c;

$formError: #e14c4c;

//Navigation color
$navColor: $color1;
$navFontColor: $black;

//font colors
$fontColor_primary: $color5;
$fontColor_secondary: $color2;

$sideNav-color: $color5;
$page-heading-color: #5e5e5e;

//border
$border_color: #ceced3;


//----------------[fonts]----------------//
$font_main: 'Bellota Text', sans-serif;


//----------------[Sizes]----------------//

//scaling
$scale1: scale(1.01);
$scale2: scale(1.1);

//border
$borderRadius: 0;
$borderSize: 2px;
$borderSize_hover: 3px;


//buttons
$buttonWidth: 200px; //fancy buttons
$buttonHeight: 55px; //fancy buttons

//icon sizes
$iconSize1: 30px;
$iconSize2: 40px;

//navigation
$navHeight_desktop: 65px;

//Navitems
$navLink_size: 30px; //nav items size

//Navlogo
$navLogoSize: $iconSize1; //nav logo size
$navLogoSize_max: 70px; //nav logo max size

//pageLayout


//pageSizes
$pagePWP_small: 95vw; //small screen width %
$pagePWP_medium: 90vw; //large screen width %

$pageW_small: 800px; //small section width
$pageW_medium: 1200px; //medium section width
$pageW_large: 1600px; //Large section width
$pageW_xLarge: 2200px; //X-large section width

//hero
$heroHeight: 60vh;
$heroHeight_max: 650px;

//content sizes
$content_fullPage: 100vw;

//footer
$footerHeight_base: 150px;


//----------[Typography]-----------//
$fontSize_base: 12px;
$fontSize_modifier: 6px;

$baseFontSize: 1rem;
$fontSize1: 1.25rem;
$fontSize2: 1.5rem;
$fontSize3: 2rem;
$fontSize4: 2.75rem;

//side menu
$sideHeader_size: 0.9rem;


//----------[Time]-----------//
$animationSpeed1: 200ms;
$animationSpeed2: 250ms;
$animationSpeed3: 300ms;
$animationSpeed4: 400ms;


//----------[Margins/Paddings]-----------//

//global margins
$gMargin: 1rem;
$spacing1: 0.8rem;
$spacing2: 1.5rem;
$spacing3: 2.5rem;
$spacing4: 4.5rem;
$spacing5: 6rem;

//Page margin
$pageOffset_top: calc(#{$navHeight_desktop}); //spacing over content on pages

//sections
$sectionPadding: $spacing3 0 $spacing5; //spacing on sections

//Navbar
$navbarPadding: 0.5rem; //navbar padding
$navItemMargins: auto 0.5rem; //margin between items & centration of items vertically
$navFontSize: 0.75rem;

//button
$btnPadding: 0.75rem 2rem; //padding for buttons


//----------------[styling]----------------//

//box styling
$dropShadow: 0 1px 5px $dropShadowColor_main;
$activeDropShadowBlack: 0 10px 15px $dropShadowColor_main;

//buttons styling
$btnBorderP: $borderSize solid $fontColor_secondary;
$btnBorderS: $borderSize solid $fontColor_secondary;
