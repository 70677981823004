@import "/src/styles/settings";

.checkout {
  @extend .pageSection;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  min-height: 100%;
}

.checkout_info {
}

.payment_wrapper {
  background-color: white;
  max-width: 900px;
  width: 100%;
  margin: 30px auto 0;
  padding-top: 30px;
}

.payment_personForm {
  max-width: 600px;
  width: 100%;
  margin: 40px auto;
  text-align: left;
  font-family: rawline, Arial, sans-serif;;

  & .form-control {
    height: 55px;
    outline: 2px solid rgba(0,0,0,0);
    &:focus {
      outline: 2px solid rgba(#96cdf6, 0.6);
      box-shadow: none;
      border: 1px solid #aaaaaa;
    }
    &.error {
      outline: none;
      box-shadow: 0 0 0 0.25rem rgba($formError, 0.25);
      border: 1px solid $formError;
      &:focus {
        outline: none;
        box-shadow: 0 0 0 0.25rem rgba($formError, 0.25);
        border: 1px solid $formError;
      }
    }
  }
  .form-label {
    font-weight: 500;
    font-size: 17px;
  }

  .react-tel-input .form-control {
    padding: 6px 12px 6px 50px !important;
    height: 55px !important;
    width: 100% !important;
    font-size: 1rem;
  }
}

.payment_separator {
  height: 83px;
  padding: 20px 23.5px;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  max-width: 600px;
  font-size: 1rem;
  text-align: left;
  color: #505050;

  & > *{
    margin-left: 0;
  }
}

.personForm_fields {
  padding: 25px 25px 0;
}

.payment_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 690px;
  padding-bottom: 50px;
  position: relative;
  margin: 40px auto 0;
  align-items: center;
  justify-content: center;

  & > iframe {
    position: static;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: fit-content !important;

    body {
      height: fit-content !important;
    }
  }
}

.checkout_disclaimer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 20px;
  font-size: 0.85rem;
}

@media screen {
  @media (min-width: 555px) {
    .personForm_fields {
      padding: 25px 63px 0;
    }

    .payment_separator {
      font-size: 1.1rem;
      & > * {
        padding-left: 22px;
      }
    }
  }
}
