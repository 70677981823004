//prize picker
.imagePicker {
  @extend .pageSection;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  .imageContainer {
    transition: outline 0.05s;
    background-color: white;
    padding: 10px;

    &.notSelected:hover {
      outline: 2px solid $color1;
    }

    &.selected > img {
      transform: scale(0.95);
    }

    img {
      object-fit: contain;
      mix-blend-mode: multiply;
    }
  }
}

.imagePicker-checkmark {
  border-radius: 100%;
  background-color: #ed4662;
  min-width: 25px;
  min-height: 25px;
  display: block;
  text-align: center;
  font-weight: 600;
  color: white;
}
