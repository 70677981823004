@import "/src/styles/settings";

.agreement {
  @extend .pageSection;

  & > div {
    margin-top: $spacing3;

    & > div:not(first-child) {
      margin-top: $spacing3;
    }
  }
}
